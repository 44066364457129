<template>
     <CForm ref="form" class="needs-validation"   >

         <CInput
             horizontal
             label="Kod"
             type="text"
             autocomplete="text"
             v-model="data.code"
             minlength="6"
             maxlength="14"
         >
         </CInput>


       <CRow>
         <CCol col="6" >
           <CInput
               label="Müşteri Id"
               horizontal
               type="text"
               autocomplete="text"
               v-model="data.customerId"
           >
           </CInput>
         </CCol>
         <CCol col="6" >
           <CInput
               label="Max Kullanım"
               horizontal
               type="text"
               autocomplete="text"
               v-model="data.maxCount"
           >
           </CInput>
         </CCol>
       </CRow>

       <CRow>
         <CCol col="6" >
           <CInput
               label="Kullanım"
               horizontal
               type="text"
               autocomplete="text"
               v-model="data.usedCount"
           >
           </CInput>
         </CCol>
         <CCol col="6" >
           <CInput
               label="Toplam Kullanım"
               horizontal
               type="text"
               autocomplete="text"
               v-model="data.totalUsedCount"
           >
           </CInput>
         </CCol>
       </CRow>

       <CRow>
         <CCol col="6" >
           <CInput
               label="İlk Geçerlilik"
               type="date"
               horizontal
               v-model="data.validDateStart"
           >
           </CInput>
         </CCol>
         <CCol col="6" >
           <CInput
               label="Son Geçerlilik"
               type="date"
               horizontal
               v-model="data.validDateEnd"
           />
         </CCol>
       </CRow>

       <CRow form class="form-group">
            <CCol tag="label" sm="2" class="col-form-label">
            <b>Geçerlilik</b>
            </CCol>
            <CCol sm="2" style="margin-left: -15px">
            <CSwitch
                class="mr-1"
                size="lg"
                color="primary"
                :checked.sync="data.validFlag"
            />
            </CCol>
            <CCol tag="label" sm="4" class="col-form-label">
            </CCol>
        </CRow>

        <CRow form class="form-group">
            <CCol tag="label" sm="2" class="col-form-label">
                <b>Kullanıldı</b>
            </CCol>
            <CCol sm="2" style="margin-left: -15px">
                <CSwitch
                    class="mr-1"
                    size="lg"
                    color="primary"
                    :checked.sync="data.approvedFlag"
                />
            </CCol>
            <CCol tag="label" sm="4" class="col-form-label">
            </CCol>
        </CRow>
        <CRow form class="form-group">
            <CCol tag="label" sm="2" class="col-form-label">
                <b>İptal Edildi</b>
            </CCol>
            <CCol sm="2" style="margin-left: -15px">
                <CSwitch
                    class="mr-1"
                    size="lg"
                    color="primary"
                    :checked.sync="data.cancelledFlag"
                />
            </CCol>
            <CCol tag="label" sm="4" class="col-form-label">
            </CCol>
        </CRow>
    </CForm>
</template>

<script>
    export default{
        name: "UserForm",
        props:{
            validated: Boolean,
            params: Object,
            actionType: String,
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
            actionType:  function(val){
                if(val == 'create')
                    this.data = {}
            }
        },
        data: function(){
            return{
                data: this.params ? {...this.params} : {},
            }
        },
    }
</script>